import {ThemeProvider} from "styled-components";
import {saasTheme} from "common/src/theme/saas";
import TermsAndConditions from "../containers/Saas/TermsAndConditions";
import React from 'react';

export default () => {
    return (
        <ThemeProvider theme={saasTheme}>
            <TermsAndConditions/>
        </ThemeProvider>
    );
};
