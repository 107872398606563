import React from 'react';

const TermsAndConditions = ({}
) => {
    return (
        <div className="container">

            <h2 style={{textAlign: "center"}}>TERMS OF USE</h2>

            <div className="row mt-4">
                <p className="text-left">The following Terms and Conditions ("Terms") are a binding contract between You
                    and Claimmunity, Inc.
                    The following Terms, as revised from time to time, govern your access or use of this website
                    (“Site”),
                    mobile applications, content, platforms, products, and services provided by Claimmunity, Inc., a
                    Delaware corporation, or any parent, subsidiary, or affiliate thereof (collectively, "Claimmunity").
                    Claimmunity owns and operates the claimmunity.com website, and related websites, mobile sites,
                    applications, platforms, APIs, integration services, and associated content, products, and services
                    including, but not limited to, offerings provided by Claimmunity pursuant to applicable Account
                    Agreements (defined below) (collectively, the "Services"). By accessing or using the Services
                    (whether
                    or not you create an account), you confirm your agreement to be bound by these Terms. If you do not
                    agree to these Terms, you may not access or use the Services. These Terms expressly supersede prior
                    agreements or arrangements with you related to the subject matter hereof (provided that any
                    agreement or
                    contract pertaining to a specific Claimmunity product or service, such as an Account Agreement
                    (defined
                    below), may be in effect in parallel with these Terms). Access to the Services is limited to persons
                    located in the United States and its territories and possessions. Terms defined in any Account
                    Agreement
                    will be given the same meaning when used in these Terms. Claimmunity may immediately terminate these
                    Terms with you (and terminate your Account if you create one), or terminate your access to the
                    Services,
                    or may generally cease offering or deny access to the Services or any portion thereof, at any time
                    for
                    any reason (provided that if stated in the terms related to the applicable Services or otherwise
                    determined appropriate by Claimmunity, you may be due a partial refund for any unused subscription
                    period or similar prepayment). Claimmunity may amend the Terms from time to time, effective upon
                    Claimmunity's posting of such updated Terms at this location. Your continued access or use of the
                    Services after such posting confirms your consent, to be bound by the Terms, as amended.
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    Claimmunity's collection and use of personal information in connection with the Services is
                    described in (a) certain Account Agreements that are applicable to specific Services which you may
                    subscribe to by creating an Account for such Services, and (b) Claimmunity's privacy policy
                    ("Privacy Policy") located at <a href="https://claimmunity.com/privacy">claimmunity.com/privacy</a>.
                </p>
            </div>

            <h3 className="row text-left">PROPRIETARY RIGHTS</h3>

            <div className="row">
                <p className="text-left">
                    Claimmunity, Inc. owns or licenses the data, content, graphics, forms, artwork, and other material
                    for this Site (the “Content”), as well as the selection, coordination, arrangement, and organization
                    and enhancement of the Content (also, the “Content”) for this Site. Except as indicated, this Site
                    and Content are owned or licensed by Claimmunity, Inc., and are © 2021 Claimmunity, Inc. and/or
                    third-party licensors. All rights reserved. Any use, reproduction or distribution of this Site or
                    Content (or Site generated content based upon Customer’s input(s)) without Claimmunity, Inc.’s
                    advance written consent is prohibited, except, however, you may download one temporary copy of these
                    materials on any single computer, and you may print one copy of these materials for your use in
                    learning about, evaluating or acquiring Claimmunity, Inc. ‘s or its licensees’ or licensors’
                    services or products. If you believe that Claimmunity, Inc. or any user of this Site has infringed
                    your copyright in any material way, please notify Claimmunity, Inc. at support@claimmunity.com. All
                    trademarks, service marks and logos appearing on the Site are proprietary to Claimmunity, Inc. or
                    its licensors. Use or misuse of these trademarks without Claimmunity, Inc.’s advance written consent
                    is expressly prohibited.
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    With the exception of User Content (defined below), and except as expressly provided in an Account
                    Agreement, you acknowledge and agree that all the intellectual property rights, including
                    copyrights, patents, trademarks, and trade secrets, in the Services and all material on or available
                    from the Services, including but not limited to data, text, photos, videos, graphics, and software
                    (collectively, "Materials") are and shall remain Claimmunity's property or the property of
                    Claimmunity's licensors.
                </p>
            </div>


            <div className="row">
                <p className="text-left">
                    Neither these Terms, Claimmunity’s provision of the Services, nor your use of the Services convey or
                    grant to you or any third party any rights: (a) in or to the Services or Materials, except for the
                    express limited license granted to you in these Terms or an Account Agreement; or (b) to use or
                    reference in any manner Claimmunity's company name, brands, logos, product and service names,
                    trademarks, or services marks, or those of Claimmunity's licensors.
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    Subject to your compliance with these Terms, until termination of these Terms with you, or of your
                    Account, Claimmunity grants you a limited, non-exclusive, non-sublicensable, revocable,
                    non-transferable license to access and use the Services (subject to any applicable Account Agreement
                    and product/service terms applicable to such Service) and Materials made available by Claimmunity
                    for use in your business, solely as expressly permitted by these Terms, and subject to all the terms
                    and conditions of these Terms, and all applicable intellectual property laws. Any rights not
                    expressly granted herein are reserved by Claimmunity and Claimmunity's licensors, and any other use
                    of the Services and Materials is strictly prohibited. </p>
            </div>

            <div className="row">
                <p className="text-left">
                    No Materials may be copied, republished, uploaded, posted, transmitted, distributed in any way, or
                    modified except as provided in an Account Agreement. Nothing contained on the Services should be
                    interpreted as granting to you any license or right to use any of the Materials or third-party
                    proprietary content on the Services without the express written permission of Claimmunity or the
                    appropriate third-party owner, as applicable.
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    The Services and Materials are protected by copyright, trademark, trade dress, patent, trade secret,
                    international treaties, or other proprietary rights and laws of the United States and other
                    countries. Except as otherwise indicated on the Services and except for the trademarks, service
                    marks, logos and trade names of other companies that are displayed on the Services, all trademarks,
                    service marks, logos, trade dress and trade names are proprietary to Claimmunity, including without
                    limitation Claimmunity and the claimmunity.com trade dress. Please be advised that Claimmunity
                    enforces its intellectual property rights to the fullest extent of the law. </p>
            </div>

            <div className="row">
                <p className="text-left">
                    You may not: (a) remove any copyright, trademark or other proprietary notices from any portion of
                    the Services or Materials; (b) except as expressly permitted under an Account Agreement, reproduce,
                    modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer,
                    publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services or
                    Materials; (c) decompile, reverse engineer, or disassemble the Services or Materials or otherwise
                    reduce the software or Materials to a human-perceivable form for any purpose, including without
                    limitation to build a product or service competitive with the Services or Materials and related
                    services except as may be permitted by applicable law; (d) except as expressly permitted under an
                    Account Agreement, link to, mirror, or frame any portion of the Services or Materials; (e) cause or
                    launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data
                    mining any portion of the Services or Materials or unduly burdening or hindering the operation or
                    functionality of any aspect of the Services or Materials, or performing any of the same manually;
                    (f) attempt to gain unauthorized access to or impair any aspect of the Services or Materials or
                    related systems or networks; (g) combine the Services or any Materials with any other data or
                    information for the purpose, or with the result, of (i) determining the identity or other personal
                    information of any person who provided information or data which was then anonymized, or (ii)
                    otherwise violating the letter or spirit of these Terms or the Account Agreement under which such
                    information was provided and the protection thereto reasonably anticipated by such persons. Any
                    future release, update, or other addition to functionality of the Services shall be subject to the
                    terms of this Agreement. All copyright and other proprietary notices on any Services content must be
                    retained on all copies thereof. </p>
            </div>

            <h3 className="row text-left">USER PROVIDED CONTENT</h3>
            <div className="row">
                <p className="text-left">
                    Claimmunity may, in Claimmunity's sole discretion, permit you from time to time to submit, upload,
                    publish, or otherwise make available to Claimmunity through the Services data or textual, audio, or
                    visual content and information. "User Content" means any and all such information, data, and other
                    content that is submitted to the Service, or to which Claimmunity is otherwise provided access as
                    part of its provision of the Service, including but not limited to any (a) shipment or
                    transportation data or information (b) commentary or feedback you provide, (c) support requests you
                    initiate, or (d) entries you submit for competitions and promotions through the Services. By
                    providing User Content to Claimmunity (through the Services, or through other communications with
                    Claimmunity, including without limitation, through SMS or MMS text/multimedia messages), you grant
                    Claimmunity a worldwide, perpetual, irrevocable, transferable, royalty-free license, with the right
                    to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display,
                    publicly perform, and otherwise exploit in any manner such User Content in all formats and
                    distribution channels now known or hereafter devised (including in connection with the Services and
                    Claimmunity's business, and on third-party sites and services), without further notice to or consent
                    from you, and without the requirement of payment to you or any other person or entity. Additionally,
                    you agree that Claimmunity shall be the owner of its compilation of User Content. All rights in and
                    to the User Content not expressly granted to Claimmunity in these Terms of Use are reserved by you.
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    You represent and warrant that: (a) you either are the sole and exclusive owner of all User Content
                    or you have all rights, licenses, consents and releases necessary to convey or otherwise make
                    available the User Content to Claimmunity and to grant Claimmunity the license to the User Content
                    as set forth above; and (b) neither the User Content, nor your submission, uploading, publishing or
                    otherwise making available of such User Content, nor Claimmunity's use of the User Content as
                    permitted herein will infringe, misappropriate, or violate a third party's intellectual property or
                    proprietary rights, or rights of personality, publicity or privacy, or any moral rights, or result
                    in the violation of any applicable law or regulation.
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    You agree not to provide User Content that (a) is defamatory, libelous, hateful, violent, obscene,
                    pornographic, unlawful, deceptive, or otherwise offensive, as determined by Claimmunity in its sole
                    discretion, whether or not such material may be protected by law, or (b) infringes any patent,
                    trademark, trade secret, copyright, right of privacy or publicity, or other proprietary rights of
                    any person or entity. Claimmunity may, but shall not be obligated to, review, monitor, or remove
                    User Content, at Claimmunity's sole discretion and at any time and for any reason, without notice to
                    you. You alone are responsible for any liability resulting from your User Content, including, but
                    not limited to, liability resulting from any of your User Content that violates these Terms of Use.
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    Claimmunity may create anonymous data records (“Anonymous Data”) from your User Content by using
                    commercially reasonable efforts to exclude any and all information (such as company name) that would
                    identify you. Claimmunity may use and disclose Anonymous Data for any purpose, including to improve
                    the Services.
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    Claimmunity may share your User Content (a) with Claimmunity’s third-party service providers; (b) if
                    another company acquires Claimmunity’s company, business, or Claimmunity’s assets, including through
                    bankruptcy, with such acquiring company; and (c) to comply with relevant laws, to respond to
                    subpoenas or warrants served on Claimmunity, to protect or defend Claimmunity’s or Claimmunity’s
                    users’ rights or property, or to investigate or assist in preventing any violation or potential
                    violation of the law or these Terms.
                </p>
            </div>

            <h3 className="row text-left">SITE CONTENT</h3>
            <div className="row">
                <p className="text-left">
                    You may not frame, capture, harvest, collect or create hypertext or other links or connections to
                    any part of the Site or Content without Claimmunity, Inc.’s advance written consent. While
                    Claimmunity, Inc. endeavors to keep the Content, and other materials on, in, and through this Site
                    up-to-date, Claimmunity, Inc. is not responsible for any errors or omissions in the Content or other
                    materials. Claimmunity, Inc. is not responsible for the accuracy or completeness of the information,
                    text, graphics, forms, links, advertisements, or other items contained within this Site.
                    Claimmunity, Inc. may make changes to the Content, this Site, or Services made available in
                    connection with this Site at any time, with or without notice, and makes no commitment to update any
                    of these items.
                </p>
            </div>

            <h3 className="row text-left">OTHER SITES</h3>
            <div className="row">
                <p className="text-left">
                    The Services may be made available to you, or accessed by you, using third party devices, such as
                    web browsers, mobile devices, and other applications, and other third-party services and content
                    (including advertising) that Claimmunity does not control. The Services may also include
                    advertisements or otherwise link to third-party websites or other content. You acknowledge that such
                    third-party services and content are not under Claimmunity’s control and Claimmunity is not
                    responsible for such services and content. Claimmunity does not endorse such third-party services
                    and content and in no event shall Claimmuinity be responsible or liable for any products or services
                    of such third-party providers. You use all third-party service and content at your own risk. When
                    you link to a third-party service or content, the applicable third party’s terms and policies apply,
                    including the third party’s privacy and data gathering practices. You should make whatever
                    investigation you feel is necessary or appropriate before proceeding with any transaction in
                    connection with such third-party services or content.
                </p>
            </div>

            <h3 className="row text-left">USER ACCOUNTS</h3>

            <div className="row">
                <p className="text-left">
                    In order to access certain features and information on the Service, you may be required to register
                    a user account or may otherwise be assigned an account allowing access to the Services by
                    Claimmunity (in either event, an "Account"), and must enter into an account agreement ("Account
                    Agreement") with Claimmunity. As used herein, Account Agreement means the agreement applicable to
                    the type of Account created by or for you (for example, different Account types may apply to
                    different roles or to different levels of service). The terms and conditions set forth in any
                    Account Agreement between you and Claimmunity are in addition to the terms and conditions set forth
                    in these Terms. </p>
            </div>

            <div className="row">
                <p className="text-left">
                    Account registration requires you to submit to Claimmunity certain information specific to your
                    Account, such as (if applicable to your type of account): your name, company name, company address,
                    company email, phone number, valid payment information, and other information depending on the type
                    of Account (as further outlined in the Privacy Policy). You agree to maintain accurate, complete,
                    and up-to-date information in your Account. Unless otherwise permitted by Claimmunity in writing,
                    you may only possess one Account.
                </p>
            </div>

            <h3>ACCOUNT REQUIREMENTS AND CONDUCT</h3>
            <div className="row">
                <p className="text-left">
                    You must be at least 18 years of age to obtain an Account and use the Services, unless a specific
                    Service permits or requires otherwise. You may not create an Account for someone under 18 years of
                    age. If you are under 18, you may not have someone over such age assist you in obtaining an Account.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    Except as permitted by an Account Agreement, you may not authorize third parties to use your
                    Account. You may not assign or otherwise transfer your Account to any other person or entity. You
                    agree to comply with all applicable laws when accessing or using the Services. You may not in your
                    access or use of the Services through an Account cause nuisance, annoyance, inconvenience, or
                    property damage to any other party.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    In certain instances, you may be asked to provide proof of identity or other methods of identity
                    verification to access or use the Services, and you agree that you may be denied access to or use of
                    the Services if you refuse to provide proof of identity or other methods of identity verification.
                    You consent to Claimmunity’s use of third-party services and information to verify your identity and
                    capacity to participate in use of the Services.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    You are responsible for maintaining the confidentiality and security of your Account and password
                    and for all activities or any other actions that occur under or are taken in connection with your
                    password or Account. You agree to (a) immediately notify Claimmunity of any known or suspected
                    unauthorized use of your password or Account, or any known or suspected breach of security,
                    including loss, theft, or unauthorized disclosure of your password or credit card information; and
                    (b) ensure that you exit from your Account at the end of each session. Claimmunity will not be
                    liable for any injury, loss, or damage of any kind arising from or relating to your failure to
                    comply with the preceding requirements or for any acts or omissions by you or someone else using
                    your Account or password.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    By accessing the Services, you agree, except as expressly permitted in an Account Agreement:
                    <br/>
                    <ul>
                        <li>to comply with all applicable laws regarding online conduct and submission of acceptable
                            User Content, including any of the foregoing that specifically relate to minors;
                        </li>
                        <li>not to access or use the Services, or submit content to the Services, if you are under the
                            age of 18;
                        </li>
                        <li>not to access the Services using a third-party's account/registration without the express
                            consent of the Account holder and solely as permitted under the applicable Account
                            Agreement;
                        </li>
                        <li>not to attempt, through any means, to gain unauthorized access to any part of the Services
                            or any service, other account, computer system or network connected to any Claimmunity
                            server;
                        </li>
                        <li>not to attempt to impersonate another user or person;</li>
                        <li>not to advertise, or solicit, any user to buy or sell any products or services, unless
                            authorized by Claimmunity;
                        </li>
                        <li>not to deep-link to the Services, or access the Services manually, or with any robot,
                            spider, web crawler, extraction software, automated process, or device to scrape, copy, or
                            monitor any portion of the Services, or any Materials, or other content on the Services;
                        </li>
                        <li>not to conduct any kind of systematic retrieval of data or other content from the
                            Services;
                        </li>
                        <li>not to create or compile, directly or indirectly, any collection, compilation, database, or
                            directory from the Services content;
                        </li>
                        <li>not to use the Services in any manner that could damage, disable, overburden, or impair any
                            Claimmunity (or its vendors') server, or the network(s) connected to any Claimmunity (or its
                            vendors') server, or interfere with any other party's use and enjoyment of the Services;
                        </li>
                        <li>not to transmit any chain letters or junk email;</li>
                        <li>not to sell or transfer your profile;</li>
                        <li>not to use the Services to engage in commercial activities apart from expressly
                            sanctioned use of Claimmunity services;
                        </li>
                        <li>not to use the Services as part of an effort to compete with Claimmunity or the
                            Services;
                        </li>
                        <li>not to copy any Materials for republication in any format or media, except as expressly
                            permitted under an Account Agreement or License Agreement;
                        </li>
                        <li>not to license, sell, or otherwise provide access to or use of the Services to any third
                            party;
                        </li>
                        <li>not to copy, publish, or redistribute any rebate or discount code or act in bad faith in
                            an attempt to manipulate or gain an unintended commercial benefit from incentive offers;
                        </li>
                        <li>not to harass, annoy, intimidate, or threaten any Claimmunity employees or agents
                            engaged in providing any portion of the Services;
                        </li>
                        <li>not to display an advertisement, or accept payment or anything of value from a third
                            person in exchange for your any commercial or promotional content on or through the
                            Services on behalf of that person, such as posting blogs or bulletins with a commercial
                            purpose;
                        </li>
                        <li>not to upload or transmit viruses or other harmful, disruptive or destructive files;
                        </li>
                        <li>not to disrupt, interfere with, or otherwise harm or violate the security of the
                            Services, or any services, system resources, accounts, passwords, servers or networks
                            connected to or accessible through the Services or affiliated or linked sites; and
                        </li>
                        <li>not to use the Services for any illegal purposes.</li>
                    </ul>
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    You agree that you will comply with all applicable laws and regulations with respect to the Services
                    and Materials, including without limitation all export and re-export control laws and regulations,
                    and you hereby indemnify and hold us harmless from and against any damages, loss, costs or expenses
                    (including reasonable attorney's fees and expenses) arising out of your violation of any such laws
                    or regulations.
                </p>
            </div>

            <div className="row">
                <p className="text-left">
                    You agree that the consequences of re-publication or commercial use of User Content or Materials
                    from the Services, or other violations of the foregoing proscriptions, may be so serious and
                    incalculable that monetary compensation may not be a sufficient or appropriate remedy and that
                    Claimmunity will be entitled to temporary and permanent injunctive relief to prohibit such use or
                    activity, without the need to prove damages or to post bond. </p>
            </div>

            <h3>TERMINATION OF ACCOUNT</h3>
            <div className="row">
                <p className="text-left">
                    Claimmunity has the right to block your current or future use of the Services, or terminate these
                    Terms and your Account, if you violate or fail to comply with any of your obligations or
                    requirements under these Terms, or under any other agreement with Claimmunity, including any Account
                    Agreement, or pursuant to any Claimmunity policy, including the Privacy Policy.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    In addition, an Account Agreement may provide that any termination of these Terms with you, or of
                    your Account, shall result in a termination of the Account Agreement and your right to access the
                    applicable Services governed thereby. </p>
            </div>

            <h3>PAYMENT FOR SERVICES</h3>
            <div className="row">
                <p className="text-left">
                    Unless otherwise provided by your Service Agreement, subscription begins on the date Services are
                    made available and shall continue indefinitely unless terminated in writing upon 30 days advance
                    notice. Claimmunity, Inc. may cancel any or all Services without notice if you violate any Terms of
                    this agreement. On termination, Claimmunity, Inc. agrees to remit all prepaid unused subscription
                    fees after adjustment for any multiple month or location discounts to reflect actual service
                    provided. Payment for additional users is due in U.S. funds at the beginning of each month in
                    advance of Services. Claimmunity, Inc. may require a security deposit payable in advance of
                    initiating any service. If any amount due Claimmunity, Inc. is not paid as specified, such amount
                    will be subject to a finance charge, equal to 1.5% of the unpaid balance per month (18% per annum).
                    Claimmunity, Inc. shall apply all payments on the account first to finance charges, and the
                    remainder, if any, to the principal. Any additional transaction charges will be invoiced at the end
                    of the month in which they occur. All billing disputes must be presented in writing to Claimmunity,
                    Inc. within 45 days of invoice date. Direct all inquiries to Claimmunity, Inc. 13515 Bellaria Circle
                    Windermere, Florida 34786 or via email at support@claimmunity.com.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    If you use Services which require payment by you, Claimmunity may process any payments made by you
                    by payment card or by ACH, using payment information provided by you, in connection with your use of
                    the Service. You hereby authorize Claimmunity to process, from time to time, all amounts due to
                    Claimmunity for any or all services or goods obtained through the use of the Services which by their
                    express terms require payment. You agree that all amounts processed are non-refundable (except as
                    may be expressly provided by Claimmunity with respect to the unused portion of any prepaid
                    subscription periods where Claimmunity discontinues the applicable Service) and that Claimmunity has
                    no obligation or liability (except in the foregoing case) to refund or return any amounts. You agree
                    to keep all payment card or other payment information current and up to date until all amounts due
                    and owing have been paid.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    Annual Terms - Your subscription begins as soon as your initial payment is processed. You will be
                    charged, in one lump sum, the annual rate stated at the time of purchase, plus applicable taxes.
                    Your subscription will automatically renew on your annual renewal date until you cancel. You
                    authorize us to store your payment method(s) and to automatically charge your payment method(s)
                    every year until you cancel.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    We will automatically charge you the then-current rate for your plan, plus applicable taxes (such as
                    VAT or GST if the rate does not include it), every year upon renewal until you cancel. We may change
                    your plan’s rate each annual renewal term, and we will notify you of any rate change with the option
                    to cancel. If the applicable VAT or GST rate (or other included tax or duty) changes during your
                    one-year term, we will accordingly adjust the tax-inclusive price for your plan mid-term on your
                    next billing date.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    Month to Month Terms - Your subscription begins as soon as your initial payment is processed. Your
                    subscription will automatically renew each month without notice until you cancel. You authorize us
                    to store your payment method(s) and to automatically charge your payment method(s) every month until
                    you cancel. We will automatically charge you the then-current rate for your plan, plus applicable
                    taxes (such as VAT or GST if the rate does not include it), every month upon renewal until you
                    cancel.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    We may change your plan’s rate each monthly renewal term, and we will notify you of any rate change
                    with the option to cancel. If the applicable VAT or GST rate (or other included tax or duty) changes
                    during your one-month term, we will accordingly adjust the tax-inclusive price for your plan on your
                    next billing date.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    If your primary payment method fails, you authorize us to charge any other payment method in your
                    account. If you have not provided us with a backup payment method(s) and you fail to provide
                    payment, or if all payment methods in your account fail, we may suspend your subscription. You can
                    edit your payment information anytime in your Settings.
                </p>
            </div>

            <h3>TEXT MESSAGING AND TELEPHONE CALLS</h3>
            <div className="row">
                <p className="text-left">
                    You agree that Claimmunity may contact you by telephone, text (SMS), or multimedia (MMS) messages
                    (including by an automatic telephone dialing system) at any of the phone numbers provided by you or
                    on your behalf in connection with your Account, and you hereby consent to receive such recurring
                    communications for transactional, operational, or informational purposes. If you have granted, or in
                    the future grant, consent to receive marketing or promotional content via text/SMS, you are not
                    required to provide this consent as a condition of purchasing any property, goods, or services, and
                    you may opt-out of receiving SMS or MMS marketing messages from Claimmunity at any time, either by
                    replying to a text message sent by Claimmunity with the word "STOP" using the mobile device that is
                    receiving the messages, or by contacting support@claimmunity.com. If you do not choose to opt-out,
                    Claimmunity may contact you in accordance with the Privacy Policy. For all Text/SMS messages and
                    telephone calls, data and calling rates apply.
                </p>
            </div>

            <h3>PRIVACY STATEMENT</h3>
            <div className="row">
                <p className="text-left">
                    Claimmunity, Inc. has a Privacy Statement disclosing what information we collect about visitors, how
                    we use such information, and the steps we take to secure such information. Click here to view the
                    Privacy Statement, which is incorporated into these Terms by reference, as if set forth fully
                    herein. Please be advised that the confidentiality of any communication or material transmitted to
                    Claimmunity, Inc. via the Site or Internet electronic mail cannot be guaranteed, including, for
                    example, personal information such as your address or name. Alternatively, you may contact
                    Claimmunity, Inc. at support@claimmunity.com, or write to us at 13515 Bellaria Circle Windermere,
                    Florida 34786.
                </p>
            </div>

            <h3>DISCLAIMER OF WARRANTY AND LIABILITY</h3>
            <div className="row">
                <p className="text-left">
                    THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                    LAW, CLAIMMUNITY AND ITS SUPPLIERS DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR
                    STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR AND
                    NON-INFRINGEMENT. IN ADDITION, CLAIMMUNITY MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING
                    THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF THE SERVICES, OR ANY SERVICES
                    OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE ACCURATE,
                    UNINTERRUPTED, OR ERROR-FREE. THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS
                    INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. CLAIMMUNITY IS NOT RESPONSIBLE
                    FOR ANY DELAYS, DELIVERY FAILURES OR OTHER DAMAGES RESULTING FROM SUCH PROBLEMS. CLAIMMUNITY DOES
                    NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY, OR ABILITY OF THIRD-PARTY PROVIDERS. YOU AGREE THAT
                    THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN
                    CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
                    YOU AGREE THAT CLAIMMUNITY IS NOT A PARTY TO ANY SHIPPER, LOGISTICS SERVICE PROVIDER, OR CARRIER
                    AGREEMENTS, AND HAS NO OBLIGATIONS THEREUNDER OR WITH RESPECT THERETO.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL CLAIMMUNITY OR ITS SUPPLIERS BE
                    LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION DIRECT, SPECIAL, INCIDENTAL, DAMAGES
                    WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF INDIRECT, PUNITIVE, OR CONSEQUENTIAL,
                    BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY
                    ADVANTAGE) ARISING OUT OF THE SERVICE, THIS AGREEMENT, AN ACCOUNT AGREEMENT, OR THE USE OF OR
                    INABILITY TO USE THE SERVICE, EVEN IF CLAIMMUNITY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES, AND REGARDLESS OF THE THEORY OF RECOVERY. YOU ARE SOLELY RESPONSIBLE FOR THE BACKUP OF YOUR
                    DATA, PROCESSING OF ANY AND ALL OS&D AND CLAIMS, AND PAYMENTS DUE FOR OS&D AND CLAIMS.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    WITHOUT LIMITATION TO THE FOREGOING, CLAIMMUNITY SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY, OR
                    LOSSES ARISING OUT OF: (A) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR
                    USE THE SERVICES; (B) ANY INACCURACY IN ANY DATA OR INFORMATION AVAILABLE THROUGH THE SERVICE; (C)
                    ANY SHIPPER, LOGISTICS SERVICE PROVIDER, OR CARRIER AGREEMENT OR TRANSACTIONS THEREUNDER; OR (D) ANY
                    TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD-PARTY PROVIDER, OR BETWEEN YOU AND ANY THIRD
                    PARTY YOU CONTACT AS A RESULT OF YOUR USE OF THE SERVICES, EVEN IF CLAIMMUNITY HAS BEEN ADVISED OF
                    THE POSSIBILITY OF SUCH DAMAGES. CLAIMMUNITY SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE
                    RESULTING FROM CAUSES BEYOND CLAIMMUNITY'S REASONABLE CONTROL.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY, YOUR SOLE REMEDY IN RESPECT OF THE SERVICES, ANY SOFTWARE,
                    OR APPLICATION PROVIDED BY CLAIMMUNITY, ANY MATERIALS, THESE TERMS, THE PRIVACY POLICY, OR ANY
                    ACCOUNT AGREEMENT, REGARDLESS OF ANY THEORY OF RECOVERY, SHALL BE TO CEASE TO USE THE SAME. THIS
                    LIMITATION SHALL NOT APPLY TO BODILY INJURY OR DEATH AND SHALL BE SUBJECT TO ANY REFUND RIGHTS
                    EXPRESSLY PROVIDED BY CLAIMMUNITY.
                </p>
            </div>
            <div className="row">
                <p className="text-left">
                    IF ANY PART OF THIS LIMITATION ON LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON,
                    THEN THE AGGREGATE LIABILITY OF CLAIMMUNITY UNDER SUCH CIRCUMSTANCES FOR LIABILITIES THAT OTHERWISE
                    WOULD HAVE BEEN LIMITED SHALL NOT EXCEED THE LOWEST LIMITATION OF LIABILITY ALLOWED BY LAW, OR, IN
                    THE EVENT SUCH AMOUNT IS NOT PRESCRIBED BY LAW, ONE HUNDRED DOLLARS ($100). THE EXISTENCE OF MORE
                    THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.
                </p>
            </div>

            <h3>INDEMNIFICATION</h3>
            <div className="row">
                <p className="text-left">
                    You agree to indemnify and hold harmless Claimmunity and its affiliates and their officers,
                    directors, employees, and agents from any and all claims, demands, losses, liabilities, and expenses
                    (including attorneys' fees), arising out of or in connection with: (a) your use of the Services or
                    services or goods obtained through your use of the Services; (b) your breach or violation of any of
                    these Terms or an Account Agreement; (c) Claimmunity's use of your User Content; (d) your violation
                    of the rights of any third party; (e) any transaction or relationship between you and any third
                    party you contact as a result of your use of the Service.
                </p>
            </div>

            <h3>GOVERNING LAW AND JURISDICTION</h3>
            <div className="row">
                <p className="text-left">
                    These Terms are governed by the laws in force in the State of Florida, and shall be interpreted
                    according to the internal laws of such State, without reference to its conflicts of laws or choice
                    of law principles. Any legal suit, action or proceeding arising out of or related to these Terms
                    shall be instituted exclusively in the federal courts of the United States or the courts of the
                    State of Florida, in each case located in the City of Orlando, and each party irrevocably submits to
                    the consent to the exclusive jurisdiction of such courts, agrees to accept service of process by
                    mail, and waives any jurisdictional or venue defenses otherwise available; provided that actions for
                    equitable relief, specific performance, or the like may be brought where necessary for enforcement.
                    If any action at law or in equity is necessary to enforce or interpret the rights arising out of or
                    relating to these Terms, the prevailing party shall be entitled to recover reasonable attorney's
                    fees, costs, and necessary disbursements in addition to any other relief to which it may be
                    entitled.
                </p>
                <p className="text-left">
                    In the case of any direct conflict between these Terms and any Account Agreement with respect to the
                    choice of law and forum selection provisions set forth in each, each agreement shall be governed by
                    the terms thereof.
                </p>
            </div>

            <h3>SECURITIES INFORMATION</h3>
            <div className="row">
                <p className="text-left">
                    This Site and the information contained herein do not constitute an offer or a solicitation of an
                    offer for the purchase or sale of any securities. This Site may contain information and press
                    releases about Claimmunity, Inc., and although this information was believed to be accurate as of
                    the date prepared, Claimmunity, Inc. disclaims any duty or obligation to update such information. To
                    the extent that any information is deemed to be a “forward looking statement” as defined in the
                    rules and regulations of the Securities Act of 1933, as amended, such information is intended to fit
                    within the “safe harbor” for forward looking information and is subject to material risk factors
                    which may or may not be disclosed herein.
                </p>
            </div>

            <h3>GENERAL</h3>
            <div className="row">
                <p className="text-left">
                    You may not assign these Terms or an Account Agreement without Claimmunity's prior written approval.
                    Claimmunity may assign these Terms or an Account Agreement, including any portion of or all of the
                    rights set forth herein, without your consent to: (a) a subsidiary or affiliate of Claimmunity; (b)
                    an acquirer of Claimmunity's equity, business or assets; or (c) a successor of Claimmunity by
                    merger. Any purported assignment in violation of this section shall be void. No joint venture,
                    partnership, employment, or agency relationship exists between you, Claimmunity or any third-party
                    as a result of these Terms, any Account Agreement, or use of the Services. If any provision of these
                    Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining
                    provisions shall be enforced to the fullest extent under law. Claimmunity's failure to enforce any
                    right or provision in these Terms shall not constitute a waiver of such right or provision unless
                    acknowledged and agreed to by Claimmunity in writing. In these Terms, the words "including" and
                    "include" mean "including, but not limited to".
                </p>
            </div>

            <h3>EVIDENCE OF CONTRACT</h3>
            <div className="row">
                <p className="text-left">
                    A printed version of these Terms, and of any notice given in electronic form, shall be admissible in judicial or administrative proceedings based upon or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
                </p>
            </div>

            <h3>CHANGES</h3>
            <div className="row">
                <p className="text-left">
                    These Terms may be changed at any time, with or without notice; your continued use of this Site after any change is made to the Terms signifies your understanding of and intention and agreement to be bound by such change. It is your responsibility to monitor these Terms and Conditions for changes.
                </p>
            </div>

            <h3>NOTICE, CONSENT TO ELECTRONIC NOTICE, RECORDS AND DISCLOSURE</h3>
            <div className="row">
                <p className="text-left">
                    Claimmunity may give notice by means of a general notice on the Services, electronic mail to your email address in your Account, telephone or text message to any phone number provided in connection with your account, or by written communication sent by first class mail or pre-paid post to any address connected with your Account. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if sent by email or telephone). You may give notice to Claimmunity only by first class mail or pre-paid post to Claimmunity, Inc., 13515 Bellaria Circle Windermere, Florida 34786, with such notice deemed given when received by Claimmunity.
                </p>
                <p className="text-left">
                    Without limitation to the foregoing, certain laws require that information, such as legal notices or disclosures, relating to you be provided or made available in writing ("Records"). Your privilege of using the Service and holding an Account is conditioned upon your consent to Claimmunity's policy of providing only electronic Records, when required by applicable law, to the email address you provide. You further agree that if such electronic notice or other Records are sent by Claimmunity to the email address or SMS/text number you provide, you are deemed to have received such notice in a legally effective manner. While generally you do have the right to have Records provided or made available on paper or in non-electronic form, and the right to withdraw your consent to have the Records provided or made available in electronic form, your sole method of withdrawing such consent is to terminate your Account and to cease to use the Service.
                </p>
            </div>

            <h3>TRADEMARKS AND SERVICE MARKS</h3>
            <div className="row">
                <p className="text-left">
                    Claimmunity.com and other logos, products and service names are trademarks and service marks owned by all licensed to Claimmunity, Inc. or its affiliates (the “Marks”). Without the prior written permission of Claimmunity, Inc or its appropriate affiliates, you agree not to display or use in any manner, the Marks. All other trademarks are the property of their respective owners.
                </p>
            </div>

            <h3>CLAIMS OF COPYRIGHT INFRINGEMENT</h3>
            <div className="row">
                <p className="text-left">
                    Claims of copyright infringement should be sent to Claimmunity, Inc., 13515 Bellaria Circle Windermere, Florida 34786.                </p>
            </div>

            <h3>USE OF THE INTERNET</h3>
            <div className="row">
                <p className="text-left">
                    Use of the Internet is solely at your own risk and is subject to all applicable local, state, national, and international laws and regulations. Neither Claimmunity, Inc. nor its contractors will be liable for any loss resulting from a cause over which they do not have direct control, including, but not limited to, failure of electronic or mechanical equipment or communication lines, telephone or other interconnect problems, computer viruses, unauthorized access, theft, operator errors, severe weather, earthquakes, natural disasters, strikes or other labor problems, wars, or governmental restrictions.
                </p>
                <p className="text-left">
                    You are responsible for obtaining the data network access necessary to use the Services. Your mobile network's data and messaging rates and fees may apply if you access or use the Services from your device or if you sign up to receive messages or reminders via mobile messaging services. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and any updates thereto. Claimmunity does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. In addition, the Services may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.                </p>
                </div>
        </div>
    )
}

export default TermsAndConditions;
